import React from "react"
import styled, { css } from "styled-components"
import { COLORS } from "../constants/"

const Content = styled.footer`
  width: 100%;
  padding: 100px 20px 100px 20px;
`

const Logo = styled.img`
  width: 100%;
  max-width: 500px;
  display: block;
  margin: 0 auto 100px auto;
  padding: 0 20px 0 20px;
`

const SocialIcons = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SocialIcon = styled.a`
  display: block;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
`

const Effect = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 100px;
  margin: 0 auto;
`

const Bar = styled.div`
  height: 50px;
  background-color: ${COLORS.BLUE};
  width: 100%;

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`

const Credit = styled.p`
  color: ${COLORS.FONT};
  text-align: center;
  margin: 0 0 40px 0;
`

export const Footer = () => (
  <>
    <Content>
      <Logo alt="Roboeduc" src={require("../images/logo.svg")} />
      <SocialIcons>
        <SocialIcon href="http://www.facebook.com/roboeduc">
          <img src={require("../images/facebook.svg")} alt="facebook" />
        </SocialIcon>

        <SocialIcon href="http://instagram.com/roboeduc">
          <img src={require("../images/instagram.svg")} alt="instagram" />
        </SocialIcon>

        <SocialIcon href="http://youtube.com/roboeduc">
          <img src={require("../images/youtube.svg")} alt="youtube" />
        </SocialIcon>
      </SocialIcons>
    </Content>
    <Credit>Roboeduc | 2019</Credit>
    <Effect>
      <Bar color={COLORS.BLUE} />
      <Bar color={COLORS.GREEN} />
      <Bar color={COLORS.PINK} />
      <Bar color={COLORS.YELLOW} />
    </Effect>
  </>
)
