import React, { useState } from "react"
import styled from "styled-components"
import { SectionTitle } from "../components"
import { COLORS } from "../constants"
import { servicesContent } from "../content"
import { darken } from "polished"
import { LazyImage, Show, Each } from "cn-react-helpers"

const Content = styled.section`
  background-color: ${COLORS.GREEN};
  background-size: 400px auto;
  padding: 100px 20px 100px 20px;
  margin-bottom: 1px;
`

const Description = styled.p`
  margin: 0 0 30px 0;
  color: ${COLORS.WHITE};
  text-align: center;
  font-size: 1.4em;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const Item = styled.div`
  background: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
`

const ItemImage = styled(LazyImage)`
  width: 100%;
  margin: 0 0 20px 0;
  min-height: 150px;
  border-radius: 5px;
`

const ItemTitle = styled.h1`
  max-width: 230px;
  width: 100%;
  margin: 0 auto 10px auto;
  font-size: 1.1em;
  font-weight: 900;
  color: ${COLORS.FONT};
  text-align: center;
  border: solid red p1x;
  text-transform: uppercase;
`

const ItemText = styled.p`
  color: ${COLORS.FONT};
  text-align: center;
  max-width: 230px;
  margin: 0 auto 30px auto;
`

const ItemButton = styled.a`
  margin: 0 auto;
  diplay: block;
  width: 100%;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9em;
  text-transform: uppercase;
  background-color: ${COLORS.BLUE};
  justify-content: center;
  align-items: center;
  height: 35px;
  color: #ffffff;
  text-align: center;
  border-radius: 100px;
  transition: all 0.3s linear 0s;

  &:hover {
    background-color: ${darken(0.1, COLORS.BLUE)};
  }
`

const Details = styled.section`
  min-height: 400px;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  color: ${COLORS.FONT};
  border-radius: 10px;
`

const DetailsImage = styled(LazyImage)`
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
`

const DetailsTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 900;
  border-bottom: solid #e4e4e4 1px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
`

const DetailsInfo = styled.div`
  border-bottom: solid #e4e4e4 1px;
  padding-bottom: 15px;
  margin-bottom: 15px;
`

const DetailsBack = styled.a`
  margin: 0 auto;
  diplay: block;
  width: 100%;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9em;
  transition: all 0.3s linear 0s;
  text-transform: uppercase;
  background-color: ${COLORS.BLUE};
  justify-content: center;
  align-items: center;
  height: 35px;
  color: #ffffff;
  text-align: center;
  border-radius: 100px;

  &:hover {
    background-color: ${darken(0.1, COLORS.BLUE)};
  }
`

export const Services = () => {
  const [open, setOpen] = useState(false)
  const [course, setCourse] = useState(" ")

  const openDetails = currentCourse => () => {
    setOpen(true)
    setCourse(currentCourse)
  }

  const closeDetails = () => {
    setOpen(false)
  }

  const ShowAllServices = () => (
    <Grid>
      <Each items={servicesContent.courses}>
        {(item, index) => (
          <Item key={`${index}-${item.title}`}>
            <ItemImage
              alt={item.title}
              loader={require("../images/loader.svg")}
              source={require(`../images/services/${item.image}`)}
            />
            <div>
              <ItemTitle> {item.title} </ItemTitle>
              <ItemText> {item.description} </ItemText>
            </div>
            <ItemButton href="#detalhes" onClick={openDetails(item)}>
              Detalhes
            </ItemButton>
          </Item>
        )}
      </Each>
    </Grid>
  )

  const ShowDetails = () => {
    return (
      <Details>
        <DetailsImage
          alt={course.title}
          loader={require("../images/loader.svg")}
          source={require(`../images/services/${course.image}`)}
        />
        <DetailsTitle>{course.title}</DetailsTitle>
        <DetailsInfo>{course.content.text}</DetailsInfo>

        <Show if={course.content.schedule}>
          <DetailsInfo>
            <b>Horário: </b> {course.content.schedule}
          </DetailsInfo>
        </Show>

        <Show if={course.content.place}>
          <DetailsInfo>
            <b>Local: </b> {course.content.place}
          </DetailsInfo>
        </Show>

        <DetailsBack href="#detalhes" onClick={closeDetails}>
          Grade de cursos
        </DetailsBack>
      </Details>
    )
  }

  return (
    <Content id="servicos">
      <SectionTitle id="detalhes"> Serviços </SectionTitle>
      <Description>{servicesContent.description}</Description>
      <Show if={open}>
        <ShowDetails />
      </Show>

      <Show if={!open}>
        <ShowAllServices />
      </Show>
    </Content>
  )
}
