import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants"
import { MainTitle } from "../components"
import { contactContent } from "../content"
import { LazyIframe } from "cn-react-helpers"

const Content = styled.div`
  width: 100%;
`

const Grid = styled.div`
  background: red;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const Data = styled.div`
  background-color: ${COLORS.BLUE};
  height: 600px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    height: 400px;
  }
`

const DataText = styled.p`
  color: #ffffff;
  font-size: 1.3em;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid rgba(0, 0, 0, 0.2) 2px;
  max-width: 400px;
  width: 100%;

  @media (max-width: 700px) {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
`

const DataIcon = styled.img`
  width: 100px;
  height: 100px;
  padding: 10px;
  margin: 0 auto 50px auto;
`

const Maps = styled.div`
  background-color: ${COLORS.GREEN};
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  height: 600px;

  @media (max-width: 700px) {
    height: 400px;
  }

  iframe {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    border: none;
  }
`

export const Contact = () => (
  <Content>
    <MainTitle> {contactContent.title} </MainTitle>
    <Grid>
      <Data>
        <DataIcon alt="contato" src={require("../images/contact.svg")} />
        <DataText>
          <b> {contactContent.adress.title} - </b>
          {contactContent.adress.content}
        </DataText>
        <DataText>
          <b> {contactContent.phone.title} - </b>
          {contactContent.phone.content}
        </DataText>
        <DataText>
          <b> {contactContent.email.title} </b>
          {contactContent.email.content}
        </DataText>
      </Data>
      <Maps>
        <LazyIframe
          title="Google maps"
          source="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2806.6729077962955!2d-35.20173586651503!3d-5.822464104789904!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb97d0f2cffa49973!2sRoboEduc!5e0!3m2!1spt-BR!2sbr!4v1561553016098!5m2!1spt-BR!2sbr"
        />
      </Maps>
    </Grid>
  </Content>
)
