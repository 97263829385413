import React from "react"
import styled from "styled-components"
import { MainTitle } from "../components"
import { COLORS } from "../constants"
import { newsContent } from "../content"
import { LazyImage, Each } from "cn-react-helpers"

const Content = styled.section`
  width: 100%;
  padding: 100px 20px 100px 20px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
`

const Article = styled.article`
  margin-bottom: 20px;
`

const ArticlePhoto = styled(LazyImage)`
  width: 100%;
  height: 200px;
  background: ${COLORS.BLUE};
  object-fit: cover;
  background: #f0f0f0;

  @media (max-width: 600px) {
    height: 200px;
  }
`

const ArticleDate = styled.p`
  border-bottom: solid ${COLORS.YELLOW} 1px;
  padding-top: 8px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  color: ${COLORS.BLUE};
  font-weight: 500;
  font-size: 1.1em;
`

const ArticleTitle = styled.a`
  margin: 10px 0 0 0;
  display: block;
  font-size: 1.3em;
  font-weight: 600;
  color: ${COLORS.FONT};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${COLORS.PINK};
  }
`

export const News = () => (
  <Content>
    <MainTitle>Notícias</MainTitle>
    <Grid>
      <Each items={newsContent}>
        {(item, index) => (
          <Article key={`${index}-${item.title}`}>
            <a href={item.link}>
              <ArticlePhoto
                alt={item.title}
                loader={require("../images/loader.svg")}
                source={item.image}
              />
            </a>

            <ArticleDate>
              {item.provider} - {item.date}
            </ArticleDate>

            <ArticleTitle href={item.link}>{item.title}</ArticleTitle>
          </Article>
        )}
      </Each>
    </Grid>
  </Content>
)
