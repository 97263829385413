import React from "react"
import styled, { css } from "styled-components"
import { COLORS } from "../constants"
import { darken } from "polished"
import { menuContent } from "../content"

const MenuWrapper = styled.nav`
  height: 40px;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 100;
  overflow-x: auto;
  z-index: 1;
`

const MenuItem = styled.a`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  display: flex;
  color: #ffffff;
  border-bottom: solid #ffffff 1px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 10;

  ${props =>
    props.bg &&
    css`
      background-color: ${props.bg};

      &:hover {
        background-color: ${darken(0.06, props.bg)};
      }
    `}
`

export const Menu = () => (
  <MenuWrapper>
    <MenuItem href="#escola" bg={COLORS.BLUE}>
      {menuContent.school}
    </MenuItem>

    <MenuItem href="#servicos" bg={COLORS.GREEN}>
      {menuContent.services}
    </MenuItem>

    <MenuItem href="#nosso-time" bg={COLORS.YELLOW}>
      {menuContent.team}
    </MenuItem>

    <MenuItem href="http://inbox.roboeduc.com.br/" bg={COLORS.PINK}>
      {menuContent.inbox}
    </MenuItem>

    <MenuItem href="https://www.helpeduc.com.br" bg={COLORS.GREEN}>
      {menuContent.helpeduc}
    </MenuItem>

    <MenuItem
      href="https://www.roboeduc.com.br/Roboeduc/conteudo/inicial"
      bg={COLORS.BLUE}
    >
      {menuContent.login}
    </MenuItem>
  </MenuWrapper>
)
