// prettier-ignore

const PT_BR = {
  title: "A escola",
  features: [
    {
      color: "PINK",
      title: "Montagem",
      text: "Desenvolvimento motor fino, habilidades manuais e criatividade na montagem de robôs."
    },
    {
      color: "BLUE",
      title: "Programação",
      text: "Raciocinio Lógico & linguagens de programação."
    },
    {
      color: "GREEN",
      title: "Interdisciplinaridade",
      text: "Português, matemática, biologia, história e muito mais com robótica."
    },
    {
      color: "YELLOW",
      title: "Colaboração",
      text: "Trabalho em grupo, iniciativa, respeito ao próximo."
    }
  ],
  text: "Utilizamos a robótica como ferramenta de apoio a sala de aula. Com essa ferramenta aliamos suas caracteristicas lúdicas a componentes curriculares como matemática, português, biologia, história e geografia."
}

const PT_PT = {
  title: "A escola",
  features: [
    {
      color: "PINK",
      title: "Montagem",
      text: "Motricidade fina, habilidades manuais e criatividade"
    },
    {
      color: "BLUE",
      title: "Programação",
      text: "Raciocínio lógico e linguagens de programação"
    },
    {
      color: "GREEN",
      title: "Interdisciplinaridade",
      text: "Articulação de disciplinas do currículo com a robótica"
    },
    {
      color: "YELLOW",
      title: "Colaboração",
      text: "Trabalho em equipa, espírito de iniciativa e capacidade de diálogo"
    }
  ],
  text:
    "Utilizamos a robótica como recurso educativo de apoio à sala de aula, aliando a sua vertente lúdica à abordagem de conceitos relacionados com os   programas curriculares de diversas disciplinas."
}

export const school = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
