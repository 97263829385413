import React from "react"
import { MainLayout } from "../layouts"
import { SEO } from "../components"
import {
  Menu,
  Header,
  School,
  Features,
  About,
  Photos,
  Services,
  Team,
  News,
  Testimony,
  Contact,
  Partners,
  Footer,
  Franchise
} from "../partials"

// import Modal from "../components/modal"

const IndexPage = () => (
  <MainLayout>
    <SEO title="Home" />
    <Menu />
    {/* <Modal /> */}
    <Header />
    <School />
    <Features />
    <About />
    <Photos />
    <Services />
    <Team />
    <News />
    <Testimony />
    <Franchise />
    <Contact />
    <Partners />
    <Footer />
  </MainLayout>
)

export default IndexPage
