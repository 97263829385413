import React from "react"
import styled, { css } from "styled-components"
import { COLORS } from "../constants"
import { MainTitle } from "../components"
import { franchiseContent } from "../content"
import { Each } from "cn-react-helpers"

const Content = styled.section`
  padding-bottom: 100px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  max-width: calc(1200px + 40px);
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.a`
  padding: 0px;
  display: block;
  border-radius: 10px;
  background-color: #D6D6D6;
  overflow: hidden;

  @media (max-width: 1000px) {
    display: block;
  }
`

const CardImage = styled.img`
  width: 100%;
`

export const Franchise = () => (
  <Content>
    <MainTitle> {franchiseContent.title} </MainTitle>

    <Grid>
      <Each items={franchiseContent.items}>
        {(item, index) => (
          <Card target="_blank" href={item.link} key={`${index}`}>
            <CardImage src={require(`../images/franchise/${item.image}`)} />
          </Card>
        )}
      </Each>
    </Grid>
  </Content>
)
