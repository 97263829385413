// prettier-ignore
export const news = [
  {
    image: "http://www.blogflaviorezende.com.br/uploads/9be84a7f582cbc40e17ba8a96eb4a098.jpg",
    title: "Roboeduc conquista 9 medalhas na Olimpíada Brasileira de Robótica ",
    date: "23/01/2019",
    provider: "Novo Noticias",
    link: "https://novonoticias.com/cotidiano/roboeduc-conquista-9-medalhas-na-olimpiada-brasileira-de-robotica/"
  },
  {
    image: "https://i.imgur.com/S2VauV2.jpg",
    title: "Sistema de Educação a Distância para a robótica criado em Natal, Roboeduc Inbox já é aplicado em 11 estados brasileiros",
    date: "22/01/2019",
    provider: "Blog da Juliska",
    link: "http://blogdajuliska.com.br/sistema-de-educacao-a-distancia-para-a-robotica-criado-em-natal-roboeduc-inbox-ja-e-aplicado-em-11-estados-brasileiros"
  },
  {
    image: "https://i.imgur.com/rLYSg0k.jpg",
    title: "Roboeduc: interiorização marca novo momento da robótica educacional",
    date: "22/05/2019",
    provider: "Glaucia Lima",
    link: "http://glaucialima.com/2019/05/22/roboeduc-interiorizacao-marca-novo-momento-da-robotica-educacional/"
  },
  {
    image: "https://i0.wp.com/www.brechando.com/wp-content/uploads/2019/03/Roboeduc-Inbox-2-1.jpg?w=1024&ssl=1",
    title: "Escola de robótica em Natal cria kits educativos",
    date: "01/05/2019",
    provider: "Brechando",
    link: "https://www.brechando.com/2019/05/escola-de-robotica-em-natal-cria-kits-educativos/"
  },
  {
    image: "https://i.imgur.com/0JOrTxv.jpg",
    title: "Roboeduc lança sistema EaD de Robótica educacional",
    date: "14/04/2019",
    provider: "Tribuna do Norte",
    link: "http://www.tribunadonorte.com.br/noticia/roboeduc-lana-a-sistema-ead-de-roba-tica-educacional/410327"
  },
  {
    image: "https://s2.glbimg.com/LX_cx5mBIPOYUbASZYeI1-VC0F8=/0x0:1040x780/1000x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2018/E/e/VS0H5QSCe3wprWLwJQPA/robo.jpeg",
    title: "Crianças assistem 'aula' com professor robô na Campus Party Natal ",
    date: "13/04/2018",
    provider: "G1",
    link: "https://g1.globo.com/rn/rio-grande-do-norte/noticia/criancas-assistem-aula-com-professor-robo-na-campus-party-natal.ghtml"
  },
  {
    image: "https://www.blogdajuliska.com.br/app/webroot/uploads/images/Arthur%20Andrade%2C%20Eug%C3%AAnio%20Pacelly%2C%20Oscar%20Rocha%2C%20Philipy%20Brito%2C%20Francisco%20Melo%20%282%29.jpg",
    title: "Roboeduc participa da maior feira de educação da América Latina",
    date: "15/04/2019",
    provider: "Novo Notícias",
    link: "https://novonoticias.com/cotidiano/roboeduc-participa-da-maior-feira-de-educacao-da-america-latina/"
  },
  {
    image: "https://i.imgur.com/6WY1UJI.jpg",
    title: "UFRN aborda em sala de aula a importância da EaD de robótica",
    date: "06/10/2018",
    provider: "Tribuna do Norte",
    link: "http://www.tribunadonorte.com.br/noticia/ufrn-aborda-em-sala-de-aula-a-importa-ncia-da-ead-de-roba-tica/426326"
  }
]
