// prettier-ignore
const PT_BR = {
  title: {
    team: "Nosso time",
    founders: "Fundadores"
  },
  description: "Um time multidisciplinar pronto para receber crianças e adolescentes.",
  founders: [
    {
      image: "aquiles.jpg",
      name: "Aquiles Burlamaqui",
      title: "(Prof. UFRN) Doutor em Engenharia da Computação",
      job: "Sócio-fundador"
    },
    {
      image: "akynara.jpg",
      name: "Akynara Burlamaqui",
      title: "(Profa. UFERSA) Doutora em Educação",
      job: "Sócia-fundadora"
    }
  ],
  team: [
    {
      image: "cassio.jpeg",
      name: "Cássio Leandro",
      title: "contato@roboeduc.com",
      job: "Diretor Executivo"
    },
    {
      image: "oscar.jpeg",
      name: "Oscar Rocha ",
      title: "oscar@roboeduc.com",
      job: "Administrativo / Financeiro"
    },
    {
      image: "jadiliana.png",
      name: "Jadiliana Tavares",
      title: "jadilianatavares@roboeduc.com",
      job: "Coordenadora Pedagógica"
    },
    {
      image: "mara.jpeg",
      name: "Mara Cristina",
      title: "maracristina@roboeduc.com",
      job: "Coordenadora Pedagógica"
    },
    {
      image: "giovanni.jpeg",
      name: "Giovanni Miro",
      title: "contato@roboeduc.com",
      job: "Coordenador Técnico"
    }
  ]
}

// prettier-ignore
const PT_PT = {
  title: {
    team: "A nossa equipa",
    founders: "Fundadores"
  },
  description: "Uma equipa multidisciplinar preparada para acompanhar crianças e jovens",
  founders: [
    {
      image: "aquiles.jpg",
      name: "Aquiles Burlamaqui",
      title: "Doutorado em / PhD Engenharia da Computação (Professor na Universidade Federal do Rio Grande do Norte, Brasil) ",
      job: "Sócio-fundador"
    },
    {
      image: "akynara.jpg",
      name: "Akinara Burlamaqui",
      title: "Doutorada em / PhD Educação (Professor na Universidade Federal Rural do Semi-Árido, Brasil)",
      job: "Sócia-fundadora"
    }
  ],
  team: [
    {
      image: "cassio.jpeg",
      name: "Cássio Leandro",
      title: "contato@roboeduc.com",
      job: "Diretor Executivo"
    },
    {
      image: "oscar.jpeg",
      name: "Oscar Rocha ",
      title: "oscar@roboeduc.com",
      job: "Administrativo / Financeiro"
    },
    {
      image: "jadiliana.png",
      name: "Jadiliana Tavares",
      title: "jadilianatavares@roboeduc.com",
      job: "Coordenadora Pedagógica"
    },
    {
      image: "mara.jpeg",
      name: "Mara Cristina",
      title: "maracristina@roboeduc.com",
      job: "Coordenadora Pedagógica"
    },
    {
      image: "giovanni.jpeg",
      name: "Giovanni Miro",
      title: "contato@roboeduc.com",
      job: "Coordenador Técnico"
    }
  ]
}

export const team = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
