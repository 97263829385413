// prettier-ignore
const PT_BR = {
  title: "Sobre nós",
  partners_title: "Nossos parceiros",
  description: {
    title: "Apaixonados por Robótica desde 2008",
    text: "A RoboEduc é uma empresa criada por pesquisadores da Universidade Federal do Rio Grande do Norte nas áreas de Educação e Computação, com foco na inovação da educação tecnológica, comprometida com o aprendizado de alunos desde o ensino infantil ao ensino superior, possibilitando que as crianças e jovens possam interagir com a realidade, desenvolvam habilidades para formular e resolver problemas, saiam da teoria para a prática usando ensinamentos obtidos em sala de aula, na interação cotidiana, nas amizades, nos princípios e valores da convivência. Nossa proposta pedagógica agrega uma nova solução de software, o sistema RoboEduc, a uma metodologia de ensino com oficinas, material didático e kits de robótica. Também oferecemos cursos de qualificação de docentes para atuarem com o auxílio da Robótica Educacional, além de cursos isolados de Introdução a Robótica e os nossos níveis de Robótica."
  },
  medal: "Única empresa de Robótica Educacional Graduada pela Inova da UFRN!"
}

// prettier-ignore
const PT_PT = {
  title: "Sobre nós",
  partners_title: "Os nossos parceiros",
  description: {
    title: "Apaixonados por Robótica desde 2008",
    text: "A RoboEduc foi criada por investigadores da Universidade Federal do Rio Grande do Norte (Brasil) nas áreas de Educação e Computação. Focada na inovação da educação tecnológica desde o ensino pré-escolar ao ensino universitário, a RoboEduc trabalha para que crianças e jovens possam interagir com a realidade, desenvolvam competências para formular e resolver problemas, passem da teoria à prática usando os conhecimentos obtidos na sala de aula, na interação quotidiana, nas relações de amizade, nos princípios e valores da convivência humana. A nossa proposta pedagógica alia uma nova solução de software — o sistema RoboEduc — a uma metodologia de ensino organizada em oficinas estruturadas em níveis, dispondo de materiais didáticos e kits de robótica. A nossa oferta formativa inclui também cursos dirigidos a professores e cursos profissionais de introdução à robótica."
  },
  medal: "A única empresa de Robótica Educacional certificada pela Inova da Universidade Federal do Rio Grande do Norte!"
}

export const about = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
