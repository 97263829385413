import React from "react"
import styled, { css } from "styled-components"
import { SectionTitle } from "../components"
import { COLORS } from "../constants"
import { teamContent } from "../content"
import { LazyImage, Each, Show } from "cn-react-helpers"

const Content = styled.section`
  padding: 100px 20px 100px 20px;
  background: ${COLORS.YELLOW};
  background-size: 400px auto;
`

const Description = styled.p`
  color: ${COLORS.WHITE};
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 40px;
`

const Grid = styled.div`
  max-width: 800px;
  margin: 0 auto;
  grid-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${props =>
    props.founders &&
    css`
      grid-template-columns: 1fr 1fr;
      max-width: 550px;
      margin-bottom: 100px;
    `}

  @media(max-width: 600px) {
    grid-gap: 0px;
    grid-template-columns: 1fr;
  }
`

const Person = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  background: ${COLORS.WHITE};
  border-radius: 10px;

  @media (max-width: 600px) {
    display: flex;
    padding: 20px 20px;
    flex-direction: row;
    margin-bottom: 10px;
  }
`

const PersonImage = styled(LazyImage)`
  width: 140px;
  height: 140px;
  border-bottom: solid ${COLORS.BLUE} 2px;
  border-left: solid ${COLORS.GREEN} 2px;
  border-right: solid ${COLORS.YELLOW} 2px;
  border-top: solid ${COLORS.PINK} 2px;
  border-radius: 100px;
  padding: 5px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
    margin-right: 15px;
  }
`

const PersonName = styled.h1`
  font-size: 1em;
  color: ${COLORS.FONT};
  font-size: 1em;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  text-align: center;

  @media (max-width: 600px) {
    text-align: left;
  }
`

const PersonJob = styled.p`
  color: ${COLORS.FONT};
  font-size: 1em;
  text-align: center;

  @media (max-width: 600px) {
    text-align: left;
  }
`

const PersonTitle = styled.p`
  color: ${COLORS.BLUE};
  text-align: center;
  word-break: break-word;

  @media (max-width: 600px) {
    text-align: left;
  }
`

const Hack = styled.div``

export const Team = () => (
  <Content id="nosso-time">
    <SectionTitle>{teamContent.title.founders}</SectionTitle>

    <Grid founders>
      <Each items={teamContent.founders}>
        {(item, index) => (
          <Person key={`${index}-${item.name}`}>
            <PersonImage
              alt={item.name}
              loader={require("../images/loader.svg")}
              source={require(`../images/team/${item.image}`)}
            />
            <div>
              <PersonName> {item.name} </PersonName>
              <PersonJob> {item.job} </PersonJob>
              <Show if={item.title}>
                <PersonTitle> {item.title} </PersonTitle>
              </Show>
            </div>
          </Person>
        )}
      </Each>
    </Grid>

    <SectionTitle>{teamContent.title.team}</SectionTitle>
    <Description>{teamContent.description}</Description>

    <Grid>
      <Each items={teamContent.team}>
        {(item, index) => (
          <>
            {/* Hack to align the elements */}
            <Show if={index === 6}>
              <Hack />
            </Show>

            <Person key={`${index}-${item.name}`}>
              <PersonImage
                alt={item.name}
                loader={require("../images/loader.svg")}
                source={require(`../images/team/${item.image}`)}
              />
              <div>
                <PersonName> {item.name} </PersonName>
                <PersonJob> {item.job} </PersonJob>
                <Show if={item.title}>
                  <PersonTitle> {item.title} </PersonTitle>
                </Show>
              </div>
            </Person>
          </>
        )}
      </Each>
    </Grid>
  </Content>
)
