import React from "react"
import styled from "styled-components"
import { Each, LazyImage } from "cn-react-helpers"
import { COLORS } from "../constants"
import { partnersContent } from "../content"

const Content = styled.section`
  width: 100%;
  margin-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
`

const Grid = styled.section`
  max-width: 1000px;
  display: grid;
  grid-gap: 20px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 820px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 700px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 360px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 340px) {
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
  }
`

const Title = styled.h1`
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: ${COLORS.BLUE};
`

const Partner = styled.div`
  display: block;
  border: solid #D5D5D5 2px;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  transition: all 0.2s linear 0s;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  position: relative;

  &:hover{
    border: solid ${COLORS.PINK} 2px;

    &::before{
      display: block;
      bottom: 0px;
      opacity: 1;
    }
  }

  &::before{
    content: "${props => props.title}";
    display: block;
    background: red;
    font-weight: bold;
    transition: all 0.2s linear 0s;
    position: absolute;
    width: 100%;
    color: ${COLORS.WHITE};
    background: ${COLORS.PINK};
    text-align: center;
    padding: 10px 10px;
    bottom: -50px;
    opacity: 0;
  }
`

const PartnerImage = styled(LazyImage)`
  max-height: 80px;
`

export const Partners = () => (
  <Content>
    <Title> Escolas Parceiras </Title>
    <Grid>
      <Each items={partnersContent}>
        {item => (
          <Partner title={item.title} target="_blank">
            <PartnerImage
              alt={item.title}
              loader={require("../images/loader.svg")}
              source={require(`../images/schools/${item.image}`)}
            />
          </Partner>
        )}
      </Each>
    </Grid>
  </Content>
)
