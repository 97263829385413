import React from "react"
import styled, { css } from "styled-components"
import { COLORS } from "../constants"
import { MainTitle } from "../components"
import { schoolContent } from "../content"
import { Each, LazyIframe } from "cn-react-helpers"

const Content = styled.section`
  width: 100%;
  background: #ffffff;
  padding-bottom: 100px;
  padding-top: 100px;
`

const Grid = styled.div`
  margin: 0 auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 20px;
  max-width: calc(1200px + 40px);
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
`

const Feature = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;

  ${props =>
    props.bg &&
    css`
      background-color: ${props.bg};
    `}
`

const FeatureTitle = styled.h1`
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.1em;
  font-weight: 900;
  margin-bottom: 15px;
`

const FeatureText = styled.p`
  color: #ffffff;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.1em;
  max-width: 280px;
`

const Center = styled.div`
  margin-bottom: 100px;
`

const Description = styled.p`
  font-size: 1.4em;
  line-height: 1.4em;
  max-width: 600px;
  font-weight: 400;
  margin: 0 auto;
  color: ${COLORS.FONT};
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  margin-bottom: 50px;
`

const Video = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    background: #f0f0f0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

export const School = () => (
  <Content id="escola">
    <Center>
      <MainTitle> {schoolContent.title} </MainTitle>
      <Description>{schoolContent.text}</Description>

      <Video>
        <EmbedContainer>
          <LazyIframe
            title="Vídeo promocional"
            source="https://www.youtube.com/embed/CV53_4xkMec"
          />
        </EmbedContainer>
      </Video>
    </Center>

    <Grid>
      <Each items={schoolContent.features}>
        {(item, index) => (
          <Feature key={`${index}-${item.title}`} bg={COLORS[item.color]}>
            <FeatureTitle> {item.title} </FeatureTitle>
            <FeatureText> {item.text} </FeatureText>
          </Feature>
        )}
      </Each>
    </Grid>
  </Content>
)
