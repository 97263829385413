import React from "react"
import styled from "styled-components"
import { SectionTitle } from "../components"
import { COLORS } from "../constants"
import { aboutContent } from "../content"
import { LazyImage } from "cn-react-helpers"

const Content = styled.section`
  padding: 100px 20px 100px 20px;
  background: ${COLORS.BLUE};
  margin-bottom: 1px;
  background-size: 400px auto;
  ${"" /* background-image: url('${require("../images/circuit.svg")}') */}
`

const Title = styled.p`
  font-weight: bold;
  font-size: 1.3em;
  color: ${COLORS.WHITE};
  text-align: center;
  margin-bottom: 20px;
  font-weight: 900;
  text-transform: uppercase;

  @media (max-width: 800px) {
    font-size: 1.1em;
  }
`

const Text = styled.p`
  font-size: 1.3em;
  line-height: 1.5em;
  color: ${COLORS.WHITE};
  width: 100%;
  max-width: 720px;
  margin: 0 auto 50px auto;

  @media (max-width: 800px) {
    font-size: 1em;
  }
`

const Medal = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 500;
    position: relative;
    font-size: 1.1em;
    top: 0px;
    color: ${COLORS.WHITE};
  }

  img {
    min-width: 60px;
    max-width: 60px;
    margin-right: 20px;
  }
`

const Partners = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const Partner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: ${COLORS.WHITE};
  border-radius: 3px;
`

const PartnerImage = styled(LazyImage)`
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
  max-height: 50px;
  min-height: 50px;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`

export const About = () => (
  <Content>
    <SectionTitle>{aboutContent.title}</SectionTitle>

    <Title>{aboutContent.description.title}</Title>
    <Text>{aboutContent.description.text}</Text>

    <Medal>
      <img alt={aboutContent.medal} src={require("../images/medal.svg")} />
      <p>{aboutContent.medal}</p>
    </Medal>

    <Partners>
      <Title> {aboutContent.partners_title} </Title>

      <Grid>
        <Partner borderColor={COLORS.BLUE}>
          <PartnerImage
            alt="parceiro CNPQ"
            loader={require("../images/loader.svg")}
            source={require("../images/partners/cnpq.png")}
          />
        </Partner>

        <Partner borderColor={COLORS.GREEN}>
          <PartnerImage
            alt="parceiro IMD"
            loader={require("../images/loader.svg")}
            source={require("../images/partners/imd.png")}
          />
        </Partner>

        <Partner borderColor={COLORS.PINK}>
          <PartnerImage
            alt="parceiro UFRN"
            loader={require("../images/loader.svg")}
            source={require("../images/partners/ufrn.png")}
          />
        </Partner>

        <Partner borderColor={COLORS.YELLOW}>
          <PartnerImage
            alt="parceiro Inova"
            loader={require("../images/loader.svg")}
            source={require("../images/partners/inova.png")}
          />
        </Partner>
      </Grid>
    </Partners>
  </Content>
)
