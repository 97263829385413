import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants"
import { headerContent } from "../content"

import Banner from "./banner"

const Wrapper = styled.section`
  width: 100%;
  border: solid white 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Logo = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: #ffffff;
  margin: 140px auto 20px auto;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 600px) {
    margin: 80px auto 20px auto;
  }
`

const Crew = styled.img`
  width: 100%;
  max-width: 800px;
  display: block;
  text-align: center;
  margin: 0 auto 50px auto;
  min-width: 500px;
  margin-left: 50%;
  transform: translateX(-50%);
`

const Description = styled.h1`
  margin: 0 auto 0 auto;
  color: ${COLORS.FONT};
  font-size: 1.3em;
  font-weight: 400;

  @media (max-width: 600px) {
    max-width: 250px;
    font-size: 1.2em;
    text-align: center;
  }
`

const Slogan = styled.h1`
  text-align: center;
  font-size: 2.3em;
  font-family: "Love Ya Like A Sister", sans-serif;
  color: ${COLORS.BLUE};
  line-height: 1.1em;

  @media (max-width: 600px) {
    font-size: 2em;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Header = () => (
  <Wrapper>
    <Logo>
      <img src={require("../images/logo.svg")} alt="Roboeduc" />
    </Logo>

    <Description>{headerContent.title}</Description>

    <Crew alt="Turma da Roboeduc" src={require("../images/group.jpg")} />

    <Slogan>{headerContent.description}</Slogan>
  </Wrapper>
)
