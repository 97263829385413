const PT_BR = {
  title: "Contato",
  adress: {
    title: "Endereço",
    content: "Rua da Saudade, 1051 Natal - RN"
  },
  phone: {
    title: "Telefones",
    content: "(84) 3012-3279 | (84) 99971-0667"
  },
  email: {
    title: "Email",
    content: " contato@roboeduc.com"
  }
}

const PT_PT = {
  title: "Contacto",
  adress: {
    title: "Endereço",
    content: "Rua da Saudade, 1051 Natal - RN"
  },
  phone: {
    title: "Telefones",
    content: "(84) 3012-3279 | (84) 99971-0667"
  },
  email: {
    title: "Email",
    content: " contato@roboeduc.com"
  }
}

export const contact = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
