// prettier-ignore
const PT_BR = {
  title: "Franquias",
  items: [
    {
      city: "Mossoró",
      image: "mossoro.png",
      link: "https://mossoro.roboeduc.com.br"
    },
    {
      city: "Maceió",
      image: "maceio.png",
      link: "https://maceio.roboeduc.com.br"
    },
    {
      city: "Poa",
      image: "poa.png",
      link: "https://poa.roboeduc.com.br"
    },
  ]
}

export const franchise = {
  PT_BR: PT_BR,
  PT_PT: PT_BR
}
