// prettier-ignore
export const partners = [
  {
    "link": "https://www.facebook.com/BambiniiEscola",
    "title": "Escola Bambini",
    "image": "bambini.jpg"
  },

  {
    "link": "https://www.melhorescola.com.br/escola/castelo-escola",
    "title": "Castelo Escola",
    "image": "castelo-escola.png"
  },

  {
    "link": "https://www.conhecaceinet.com.br",
    "title": "CEI Romualdo Galvão",
    "image": "cei.png"
  },

  {
    "link": "https://www.conhecaceinet.com.br",
    "title": "CEI Roberto Freire",
    "image": "cei.png"
  },

  {
    "link": "https://www.facebook.com/escolainfante/",
    "title": "Infante",
    "image": "infante.jpg"
  },

  {
    "link": "https://www.colegiomariejost.org.br",
    "title": "Colégio Marie Jost",
    "image": "marie_jost.png"
  },

  {
    "link": "https://www.escolasereaprender.com.br",
    "title": "Ser & Aprender",
    "image": "ser-aprender.png"
  },

  {
    "link": "https://www.instagram.com/centroeducacionalsaber/?hl=pt",
    "title": "Centro Educaional do Saber",
    "image": "ces.jpg"
  },

  {
    "link": "#",
    "title": "Espaço da Criança",
    "image": "espaco_crianca.jpeg"
  },

  {
    "link": "#",
    "title": "Smart Kids",
    "image": "smart-kids.png"
  },

  {
    "link": "#",
    "title": "Colégio Brasil",
    "image": "colegio_br.png"
  },

  {
    "link": "#",
    "title": "Diógenes Juca",
    "image": "diogenes.png"
  },

  {
    "link": "#",
    "title": "IEP",
    "image": "iep.png"
  },

  {
    "link": "#",
    "title": "Virgínio Augusto",
    "image": "virginio_augusto.png"
  },

  {
    "link": "#",
    "title": "Arte do Saber",
    "image": "arte_saber.jpeg"
  },

  {
    "link": "#",
    "title": "Jorge de Lima",
    "image": "jorge_lima.png"
  },

  {
    "link": "#",
    "title": "Conexão Aba",
    "image": "conexao_aba.jpeg"
  },
]