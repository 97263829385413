const PT_BR = {
  school: "A Escola",
  services: "Serviços",
  inbox: "Roboeduc Inbox",
  team: "Equipe",
  login: "Portal do aluno",
  helpeduc: "HelpEduc"
}

const PT_PT = {
  school: "A Escola",
  services: "Serviços",
  inbox: "Roboeduc Inbox",
  team: "Equipa",
  login: "Portal do aluno",
  helpeduc: "HelpEduc"
}

export const menu = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
