import { school } from "./school"
import { features } from "./features"
import { about } from "./about"
import { services } from "./services"
import { team } from "./team"
import { news } from "./news"
import { testimony } from "./testimony"
import { header } from "./header"
import { contact } from "./contact"
import { menu } from "./menu"
import { partners } from "./partners"
import { franchise } from "./franchise"

const LANG = process.env.LANG ? process.env.LANG : "PT_BR"

const schoolContent = school[LANG]
const featuresContent = features[LANG]
const aboutContent = about[LANG]
const servicesContent = services[LANG]
const teamContent = team[LANG]
const newsContent = news
const testimonyContent = testimony[LANG]
const headerContent = header[LANG]
const contactContent = contact[LANG]
const menuContent = menu[LANG]
const partnersContent = partners
const franchiseContent = franchise[LANG] 

export {
  schoolContent,
  featuresContent,
  aboutContent,
  servicesContent,
  teamContent,
  newsContent,
  testimonyContent,
  headerContent,
  contactContent,
  menuContent,
  partnersContent,
  franchiseContent
}
