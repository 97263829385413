import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants/"
import { featuresContent } from "../content"
import { Each } from "cn-react-helpers"
import { SectionTitle } from "../components"

const Content = styled.section`
  width: 100%;
  background-color: ${COLORS.PINK};
  padding: 100px 20px 100px 20px;
  margin-bottom: 1px;
  background-size: 400px auto;
  ${"" /* background-image: url('${require("../images/circuit.svg")}') */}
`

const Grid = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

const Feature = styled.div`
  background: #ffffff;
  padding: 40px;
  display: flex;
  border-radius: 10px;

  @media (max-width: 500px) {
    display: block;
  }
`

const FeatureIcon = styled.img`
  width: 80px;
  height: 80px;
  align-self: center;

  @media (max-width: 500px) {
    margin: 0 auto 40px auto;
    display: block;
  }
`

const FeatureContent = styled.div`
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 500px) {
    display: block;
    padding-left: 0;
  }
`

const FeatureTitle = styled.h1`
  font-size: 1.1em;
  color: ${COLORS.FONT};
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 900;
`

const FeatureText = styled.p`
  color: ${COLORS.FONT};
  margin-bottom: 0px;
`

export const Features = () => (
  <Content>
    <SectionTitle>{featuresContent.title}</SectionTitle>

    <Grid>
      <Each items={featuresContent.items}>
        {(item, index) => (
          <Feature key={`${index}-${item.title}`}>
            <FeatureIcon
              alt={item.title}
              src={require(`../images/features/${item.icon}`)}
            />
            <FeatureContent>
              <FeatureTitle> {item.title} </FeatureTitle>
              <FeatureText> {item.text} </FeatureText>
            </FeatureContent>
          </Feature>
        )}
      </Each>
    </Grid>
  </Content>
)
