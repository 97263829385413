// prettier-ignore
const PT_BR = {
  title: "Depoimentos",
  items: [
    {
      color: "PINK",
      author: "- Gustavo Diogenes (Pai de aluno da Roboeduc)",
      text: "A Roboeduc proporciona aos meus filhos um espaço para desenvolver habilidades e a criatividade que é raro nas escolas. O mais novo que participa do clubinho aprende a resolver problemas e criar soluções e se sente empolgado em participar das atividades. O mais velho nunca teve dificuldades na escola e na RoboEduc ele teve a chance de participar de atividades desafiadoras que ele adora."
    },
    {
      color: "BLUE",
      author: "- Joana D'arc (Mãe de aluno da Roboeduc)",
      text: "A Robótica foi a melhor coisa que apareceu para Beatriz, ela desenvolveu a concentração, interação, raciocínio lógico, psicomotricidade e ainda se diverte muito. Eles tem uma equipe muito bem capacitada, com um bom relacionamento com os pais e jeito especial  de lidar com as crianças. Tornou as manhãs de sábado de minha filha muito mais educativas e divertidas. Parabéns a toda a equipe."
    },
    {
      color: "GREEN",
      author: "- Rômulo César e Nerinha Diógenes (Pais de aluno da Roboeduc)",
      text: "A idéia de participar do Clubinho de robótica foi de nosso filho. Inicialmente pensavamos que seria como um hobby, mas ele se encantou e se pudesse teria aula de robótica todos os dias. Para nós, é muito interessante, pois agrega várias outras situações boas, como socialização, conhecimentos gerais, etc. Uma escola de ensino regular, que tenha em sua grade algum dia com aula de robótica é a minha melhor opção de escola para ele no ensino fundamental."
    }
  ]
}

// prettier-ignore
const PT_PT = {
  title: "Testemunho",
  items: [
    {
      color: "PINK",
      author: "- Gustavo Diogenes (Pai de aluno da Roboeduc)",
      text: "A Roboeduc proporciona aos meus filhos um espaço para desenvolverem habilidades e a criatividade, o que é raro nas escolas. O mais novo, que participa do clubinho, aprende a resolver problemas e criar soluções e se sente empolgado em participar das atividades. O mais velho nunca teve dificuldades na escola e na RoboEduc ele teve a chance de participar de atividades desafiadoras que ele adora."
    },
    {
      color: "BLUE",
      author: "- Joana D'arc (Mãe de aluno da Roboeduc)",
      text: "A Robótica foi a melhor coisa que apareceu para Beatriz: ela desenvolveu a concentração, interação, raciocínio lógico, psicomotricidade e ainda se diverte muito. Eles tem uma equipe muito bem capacitada, com um bom relacionamento com os pais e um jeito especial de lidar com as crianças. Tornou as manhãs de sábado de minha filha muito mais educativas e divertidas. Parabéns a toda a equipe."
    },
    {
      color: "GREEN",
      author: "- Rômulo César e Nerinha Diógenes (Pais de aluno da Roboeduc)",
      text: "A idéia de participar do Clubinho de robótica foi de nosso filho. Inicialmente, pensávamos que seria como um hobby, mas ele se encantou e se pudesse teria aulas de robótica todos os dias. Para nós, é muito interessante, pois agrega várias outras situações boas, como socialização, conhecimentos gerais, etc. Uma escola de ensino regular que tenha em sua grade algum dia com aula de robótica é a melhor opção de escola para ele no ensino fundamental."
    }
  ]
}

export const testimony = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
