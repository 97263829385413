import React from "react"
import styled from "styled-components"
import { LazyImage } from "cn-react-helpers"

const Grid = styled.section`
  width: 100%;
  max-width: 1000%;
  margin: 0 auto 1px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Image = styled(LazyImage)`
  width: 100%;
  object-fit: cover;
  height: 300px;
  background: #f0f0f0;

  @media (max-width: 900px) {
    height: 150px;
  }

  @media (max-width: 550px) {
    height: 120px;
  }
`

export const Photos = () => (
  <Grid>
    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo1.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo3.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo4.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo5.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo6.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo7.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo8.jpg")}
    />

    <Image
      alt="Alunos na Roboeduc"
      loader={require("../images/loader.svg")}
      source={require("../images/photos/photo9.jpg")}
    />
  </Grid>
)
