import React from "react"
import { LazyImage } from "cn-react-helpers"
import styled from "styled-components"

import { COLORS } from "../constants/colors"

const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0 3rem 0;
  margin: 2rem 0;
  background-color: ${COLORS.BLUE};
  width: 100%;
`

const BannerImage = styled(LazyImage)`
  width: 100%;
  max-width: 900px;
  border-radius: 4px;
  transition: 250ms;
  &:hover {
    transform: scale(1.01, 1.01);
  }
`

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 400;
  font-family: "Love Ya Like A Sister", sans-serif;
  color: ${COLORS.WHITE};
  padding: 3rem;
`

const LinkButton = styled.a`
  border: none;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  padding: 0.6rem;
  border-radius: 4px;
  margin: 8px;
  top: 0;
  right: 0;
  text-align: center;
  text-decoration: none;
  width: 90%;
  font-weight: 400;
  font-size: 1.3rem;
  transition: 450ms;

  &:active {
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }
`

const Banner = () => {
  return (
    <Content>
      <Title>Curso EAD</Title>
      <a
        href="https://api.whatsapp.com/send?phone=5584999710667&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20cursos%20EAD%20e%2Fou%20Presencial"
        alt="EAD"
      >
        <BannerImage
          loader={require("../images/banner.png")}
          source={require("../images/banner.png")}
        />
      </a>
      <LinkButton href="https://api.whatsapp.com/send?phone=5584999710667&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20cursos%20EAD%20e%2Fou%20Presencial">
        Entrar em contato
      </LinkButton>
    </Content>
  )
}
export default Banner
