import React from "react"
import styled, { css } from "styled-components"
import { COLORS } from "../constants"
import { MainTitle } from "../components"
import { testimonyContent } from "../content"
import { Each } from "cn-react-helpers"

const Content = styled.section`
  padding-bottom: 100px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  max-width: calc(1200px + 40px);
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.article`
  padding: 40px 30px 40px 30px;
  background-color: ${COLORS.BLUE};
  border-radius: 10px;

  @media (max-width: 1000px) {
    display: block;
  }

  ${props =>
    props.bg &&
    css`
      background-color: ${props.bg};
    `};
`

const CardText = styled.p`
  color: #ffffff;
  max-width: 500px;
  font-size: 1.1em;
  margin: 0 auto 15px auto;
  font-style: oblique;
  padding-bottom: 15px;
  border-bottom: solid rgba(255, 255, 255, 0.3) 1px;

  @media (max-width: 1000px) {
    max-width: 700px;
  }
`

const CardAuthor = styled.p`
  color: #ffffff;
  font-weight: 900;
  max-width: 500px;
  width: 100%;

  @media (max-width: 1000px) {
    max-width: 700px;
  }
`

export const Testimony = () => (
  <Content>
    <MainTitle> {testimonyContent.title} </MainTitle>

    <Grid>
      <Each items={testimonyContent.items}>
        {(item, index) => (
          <Card key={`${index}-${item.author}`} bg={COLORS[item.color]}>
            <CardText>{item.text}</CardText>
            <CardAuthor>{item.author}</CardAuthor>
          </Card>
        )}
      </Each>
    </Grid>
  </Content>
)
