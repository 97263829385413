// prettier-ignore
const PT_BR = {
  title: "Diferenciais",
  items: [
    {
      icon: "robotica-educacional.svg",
      title: "Robótica Educacional",
      text: "A Robótica Educacional é uma proposta educativa que buscar incentivar os alunos a pensarem de forma eficiente, lúdica, prática e criativa para resolverem os problemas gerados pelo que foi estudado em sua sala de aula escolar, assimilando assim os conceitos aprendidos com a experimentação prática da robótica, mediante o trabalho colaborativo no cotidiano dos níveis de robótica."
    },
    {
      icon: "dinamicas-dos-encontros.svg",
      title: "Dinâmica dos Encontros Estudados",
      text: "A cada encontro, são apresentados problemas e os alunos precisam pensar em estratégias para resolvê-los utilizando as ferramentas disponíveis em cada nível. Para estimular ainda mais a criatividade, os robôs precisam ser modificados para conseguirem resolver os problemas sugeridos, o que favorece a construção do conhecimento de forma eficiente e divertida!"
    },
    {
      icon: "aprendizado-coletivo.svg",
      title: "Aprendizado Colaborativo",
      text: "Nossos alunos são estimulados a trabalharem em grupo, em um processo educacional onde os participantes ajudam e confiam uns nos outros para atingirem um objetivo definido."
    },
    {
      icon: "erro-construtivo.svg",
      title: "Erro Construtivo",
      text: "Em nossas oficinas, trabalhamos para transformar os eventuais erros em fonte de conhecimento, considerando as tentativas, os erros e acertos e os possíveis insucessos como parte do processo de ensino e aprendizagem e apoiando os alunos para que não se sintam constrangidos pelos possíveis erros mas sim incentivando-os a buscarem outras estratégias para a resolução dos problemas para que essas tentativas sejam significativas para os alunos."
    },
    {
      icon: "conteudos-estudados.svg",
      title: "Conteúdos estudados",
      text: "Todas as oficinas envolvem, além de montagem e programação de robôs, conteúdos que façam referência ao que está sendo estudado nos diferentes níveis de ensino, tomando por base as disciplinas curriculares tais como Matemática, Língua Portuguesa, Biologia, entre outras. Além disso, dispomos de um ambiente virtual onde os alunos tem acessos aos materiais digitais das oficinas, para revisarem os conteúdos vistos nas aulas passadas, disponível também para os pais e demais usuários que necessitem acompanhar o andamento das atividades dos alunos nos níveis."
    },
    {
      icon: "niveis-de-robotica.svg",
      title: "Níveis de Robótica",
      text: "Cada nível é composto por uma faixa etária diferente, para que os alunos sintam-se mais próximos uns dos outros e possam construírem juntos suas opiniões sobre os mais diversos temas, por meio de estratégias que estimulem o raciocínio lógico, a criatividade, o fazer colaborativamente e a oralidade."
    },
  ]
}

// prettier-ignore
const PT_PT = {
  title: "Diferenciais",
  items: [
    {
      icon: "robotica-educacional.svg",
      title: "Robótica Educativa",
      text: "É uma proposta pedagógica que pretende incentivar os alunos a resolverem de forma autónoma, eficiente e criativa os desafios suscitados pelo trabalho em sala de aula; a assimilação dos conceitos aprendidos na escola faz-se, assim, com a experimentação prática da robótica em trabalho colaborativo."
    },
    {
      icon: "dinamicas-dos-encontros.svg",
      title: "Dinâmica das Sessões",
      text: "Cada sessão parte de problemas que os alunos terão de resolver, pensando em estratégias e usando as ferramentas disponíveis para cada nível. Os robôs podem ser alterados para solucionar novos problemas, o que estimula a criatividade e favorece a construção do conhecimento de forma progressiva, eficiente e divertida!"
    },
    {
      icon: "aprendizado-coletivo.svg",
      title: "Aprendizagem Colaborativa",
      text: "Os nossos alunos são estimulados a trabalhar em equipa, num processo educativo em que os participantes aprendem a confiar uns nos outros e se entreajudam para atingirem um objetivo comum."
    },
    {
      icon: "erro-construtivo.svg",
      title: "Erro Construtivo",
      text: "Nas nossas oficinas, procuramos transformar os erros em fonte de conhecimento: todas as tentativas, erros e correções e os eventuais insucessos são parte integrante do processo de ensino-aprendizagem. Os alunos são incentivados a encarar o erro de forma positiva e a procurar novas estratégias para a resolução dos problemas."
    },
    {
      icon: "conteudos-estudados.svg",
      title: "Conteúdos Abordados",
      text: "Todas as oficinas abordam, além da montagem e programação de robôs, conteúdos relacionados com disciplinas das diferentes áreas do saber (Matemática, Português, Biologia, Física, História, Geografia, Artes, etc.)."
    },
    {
      icon: "niveis-de-robotica.svg",
      title: "Níveis de Robótica",
      text: "Cada nível de aprendizagem corresponde a uma faixa etária determinada facilitando a relação entre os alunos e a construção conjunta de opiniões sobre os mais diversos temas. As estratégias implementadas pretendem estimular o raciocínio lógico, a criatividade, o trabalho colaborativo e a interação oral."
    },
    {
      icon: "acompanhamento.svg",
      title: "Acompanhamento",
      text: "Dispomos de um ambiente virtual que permite aos alunos o acesso aos materiais digitais das oficinas, em que poderão rever os conteúdos já abordados. Estes materiais estão também disponíveis para pais e outros utilizadores que pretendam acompanhar as atividades desenvolvidas."
    }
  ]
}

export const features = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
